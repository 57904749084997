<template>
  <form
    :action="ssoUrl"
    autocomplete="off"
    name="saml-form"
    method="post"
  >
    <login-wrapper
      title="Enterprise Login"
      :show-mandatory-field="false"
      @primaryButton.once="handleSso"
    >
      <input
        :value="samlRequest"
        type="hidden"
        name="SAMLRequest"
      >
      <input
        v-if="relayState"
        :value="relayState"
        type="hidden"
        name="RelayState"
      >
      <input
        v-if="passOnEmail"
        :value="loginEmail"
        type="hidden"
        name="UserName"
      >
      <v-row no-gutters>
        <v-col class="text-center">
          <img
            :src="companyLogo(companyData)"
            class="company-logo"
          >
        </v-col>
        <v-col cols="12">
          <p class="text-center my-8">
            {{ greetings }}, please log in with your {{ companyName }} enterprise account.
          </p>
        </v-col>
      </v-row>
    </login-wrapper>
  </form>
</template>

<script>
import loginSso from '@kickbox/common-util/src/mixins/loginSso';
import LoginWrapper from './LoginWrapper';

export default {
  name: 'LoginSso',
  components: {
    LoginWrapper
  },
  mixins: [loginSso],
  data() {
    return {};
  },
  computed: {
    companyName() {
      return this.companyData.get('name');
    },
    greetings() {
      return this.userName ? `Hi ${this.userName}` : 'Hi';
    }
  },
  methods: {
    handleSso() {
      if (this.usePost) {
        // Submit form
        return document.forms['saml-form'].submit();
      }

      return this.redirectUser();
    },
  }
};
</script>

<style scoped lang="scss">
  ::v-deep .wrapper-title {
    text-align: center;
  }
  .company-logo {
    height: 50px;
  }
</style>
