<template>
  <v-app>
    <v-row
      justify="center"
      align="center"
    >
      <div class="login-container">
        <migration :email="$route.query.email">
          <app-button
            class="mt-4"
            block
            secondary
            @click="backButton"
          >
            back to main
          </app-button>
        </migration>
      </div>
    </v-row>
  </v-app>
</template>

<script>
import { Migration } from '@kickbox/common-components';
import analyticsService from '@/services/analyticsService';

export default {
  name: 'MigrationLogin',
  components: {
    Migration
  },
  created() {
    analyticsService.trackCustomEvent('migration-screen-loaded');
  },
  methods: {
    backButton() {
      this.$router.push({ name: 'MainLogin' });
    }
  }
};
</script>

<style scoped>
::v-deep .migration-container {
  width: initial;
  padding: 0 3rem;
  text-align: center;
  .description-container {
    line-height: initial;
  }
}

</style>
