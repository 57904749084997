<template>
  <v-app>
    <v-row
      justify="center"
      align="center"
    >
      <div class="login-container">
        <div class="pa-10">
          <h3 class="mb-6 font-weight-bold wrapper-title">
            {{ title }}
          </h3>
          <slot />
          <app-button
            :loading="loadingButton"
            block
            @click="primaryButton"
          >
            {{ buttonLabel }}
          </app-button>
          <app-button
            v-if="showBackButton"
            class="mt-4"
            block
            secondary
            @click="backButton"
          >
            back to main
          </app-button>
          <p
            v-if="showMandatoryField"
            class="mandatory-fields"
          >
            * Mandatory field
          </p>
        </div>
      </div>
      <snack-bar />
    </v-row>
  </v-app>
</template>

<script>
import { SnackBar } from '@kickbox/common-admin';

export default {
  name: 'LoginWrapper',
  components: {
    SnackBar
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: 'Continue'
    },
    loadingButton: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    showMandatoryField: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    primaryButton() {
      this.$emit('primaryButton');
    },
    backButton() {
      this.$router.push({ name: 'MainLogin' });
    }
  }
};
</script>

<style scoped lang="scss">
.mandatory-fields {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
