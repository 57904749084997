<template>
  <v-app>
    <login-wrapper
      title="Admin Login"
      :show-back-button="false"
      @primaryButton="login"
    >
      <v-text-field
        v-model="email"
        label="Business email address *"
        required
        @keyup.enter="login"
      />
    </login-wrapper>
  </v-app>
</template>

<script>
import { SSO, SSO_ADMIN } from '@kickbox/common-util/constants/feature-names';
import { PASSWORD } from '@kickbox/common-util/constants/login-mode';
import userService from '@/services/userService';
import companyService from '@/services/companyService';
import LoginWrapper from './LoginWrapper';

export default {
  name: 'Login',
  components: {
    LoginWrapper
  },
  data() {
    return {
      email: '',
      company: '',
      loadingButton: false,
    };
  },
  created() {
    localStorage.removeItem('loginEmail');
  },
  methods: {
    async login() {
      if (!this.email) {
        this.$store.dispatch('showSnackBar', { text: 'Please, enter an email address.' });
        return;
      }
      const emailAddress = this.email.toLowerCase();
      localStorage.setItem('loginEmail', emailAddress);
      const user = await userService.getBasicUserInfo(emailAddress);

      if (!user) {
        this.$store.dispatch('showSnackBar', { text: 'The email address needs to be already registered on the platform.' });
        return;
      }

      const company = (user && user.company)
          || (await companyService.findCompanyByEmail(emailAddress));
      const allowedAccess = company && company.get('whiteEmailMigration') === emailAddress;
      if (company && company.get('migrated') && !allowedAccess) {
        this.$router.push({ name: 'MigrationLogin', query: { email: emailAddress } });
        return;
      }
      const companyFeatures = company && company.get('features');
      const ssoEnabled = companyFeatures && companyFeatures.includes(SSO);
      const ssoAdminEnabled = companyFeatures && companyFeatures.includes(SSO_ADMIN);

      if (ssoEnabled && ssoAdminEnabled
          && !(user && user.loginMode === PASSWORD)) {
        const userName = user.name;
        this.$router.push({ name: 'LoginSso', params: { userName } });
      } else {
        this.$router.push({ name: 'Login', params: { emailAddress } });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.login-container {
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }
  .btn {
    margin: 6px 0;
  }
}
</style>
