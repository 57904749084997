<template>
  <v-app>
    <LoginWrapper
      title="Create / reset password"
      button-label="Reset password"
      @primaryButton="resetEmailPassword(email)"
    >
      <v-text-field
        v-model="email"
        label="Business email address *"
        type="email"
        required
      />
    </LoginWrapper>
  </v-app>
</template>

<script>
import UserService from '@/services/userService';
import LoginWrapper from './LoginWrapper';

export default {
  name: 'ResetPassword',
  components: {
    LoginWrapper
  },
  props: ['emailProp'],
  data() {
    return {
      email: ''
    };
  },
  created() {
    if (this.emailProp) {
      this.email = this.emailProp;
    }
  },
  methods: {
    async resetEmailPassword(email) {
      return UserService.resetPassword(email)
        .then(() => this.$store.dispatch('showSnackBar', { text: 'An email was sent with instructions.' }))
        .catch((err) => this.$store.dispatch('showSnackBar', { text: err.message }));
    }
  }
};
</script>
