import * as Sentry from '@sentry/vue';

export default {
  props: {
    userName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      usePost: false,
      passOnEmail: false,
      ssoUrl: '',
      relayState: window.location.origin,
      samlRequest: '',
      redirectUrl: '',
      companyData: null,
      loginEmail: null,
      serviceIdentifier: null,
      signed: false
    };
  },
  async created() {
    this.loginEmail = this.$route.params.email;
    this.companyData = this.$route.params.company;
    if (this.companyData) {
      const ssoData = this.companyData.get('ssoData');
      const companySlug = this.loginEmail.split('@')[1].split('.')[0];
      if (ssoData) {
        this.serviceIdentifier = ssoData.serviceIdentifier
          ? ssoData.serviceIdentifier : companySlug;
        this.signed = !!ssoData.signed;
        this.usePost = !!ssoData.usePost;
        this.passOnEmail = !!ssoData.passOnEmail;
      } else {
        this.serviceIdentifier = companySlug;
      }
      this.getSsoUrl();
    }
  },
  methods: {
    companyLogo(company) {
      return company && company.get('logo');
    },
    getSsoUrl() {
      try {
        // Get SAML server url from api
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            const { context, entityEndpoint, redirectUrl } = JSON.parse(xhr.response);
            // HTTP-POST binding
            this.samlRequest = context;
            this.ssoUrl = entityEndpoint;
            // Redirect binding
            this.redirectUrl = redirectUrl;
          }
        };
        xhr.open('GET', `${process.env.VUE_APP_PARSE_SERVER_URL}/../rest/saml/${this.serviceIdentifier}/logininfo${this.usePost ? '-post' : ''}${this.signed ? '/signed' : ''}`);
        return xhr.send();
      } catch (errorResponse) {
        Sentry.captureException(errorResponse);
        throw new Error('Failed initializing SSO');
      }
    },
    redirectUser() {
      let { redirectUrl } = this;
      if (this.passOnEmail) {
        redirectUrl = `${redirectUrl}&UserName=${this.loginEmail}`;
      }
      window.location = `${redirectUrl}&RelayState=${this.relayState}`;
    },
  }
};
