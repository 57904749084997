<template>
  <v-app>
    <LoginWrapper
      title="Login"
      button-label="Login"
      @primaryButton="login"
    >
      <v-text-field
        v-model="email"
        label="Business email address *"
        disabled
        required
        @keyup.enter="login"
      />
      <a
        class="d-block reset-button"
        @click="resetPassword"
      >
        Create / reset password
      </a>
      <v-text-field
        v-model="password"
        :append-icon="showPass ? 'visibility_off' : 'visibility'"
        :type="showPass ? 'text' : 'password'"
        label="Password *"
        required
        class="mt-0"
        @click:append="showPass = !showPass"
        @keyup.enter="login"
      />
    </LoginWrapper>
  </v-app>
</template>

<script>
import UserService from '@/services/userService';
import LoginWrapper from './LoginWrapper';

export default {
  name: 'Login',
  components: {
    LoginWrapper
  },
  props: {
    emailAddress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      email: '',
      password: '',
      showPass: false,
      loadingButton: false,
    };
  },
  created() {
    if (this.emailAddress) {
      this.email = this.emailAddress;
    }
  },
  methods: {
    login() {
      if (!this.email || !this.password) {
        this.$store.dispatch('showSnackBar', { text: 'Please, fill in all the mandatory fields.' });
      } else {
        this.loadingButton = true;
        UserService.loginAdmin({ loginEmail: this.email, password: this.password })
          .finally(() => {
            this.loadingButton = false;
          });
      }
    },
    resetPassword() {
      this.$router.push({ name: 'ResetPassword', params: { emailProp: this.email } });
    }
  }
};
</script>

<style scoped lang="scss">
.login-container {
  .reset-button {
    text-align: right;
    font-size: 13px;
    font-weight: 500;
  }
}
</style>
